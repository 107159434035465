<template>
  <div id="info-m">
    <div class="header"></div>
    <div class="line2-fu">
      <div class="line2">
        <div class="line2-ctx">
          <div class="line2">
            <div class="line2-1">湖南果实智能科技有限公司</div>
            <div class="line2-2">----始于2018，创新永不止步</div>
            <div class="line2-3">
              <div class="line2-3-right">
                <div class="rights-3">
                  湖南果实智能科技有限公司是一家拥有高度自主核心知识产权的高新技术企业。公司立足于全国前沿教育，致力于未来科技教育现代化解决方案和一站式落地运营服务提供商，多项新技术、新产品获得国家专利，并取得了国家教育部、信息产业部等多部门的资质认证。
                </div>
                <div class="rights-4">
                  公司已开发出具有独立知识产权的AI
                  Effect引擎、数学问题模型、语音智能测评等体系，拥有涵盖中小学全年段、全学科的教学资源，知识点短视频30000多个，题库1600万道，依托强大教学资源和技术积累研发出晓果智学，希望通过AI自适应教育教学模式，为实现天下学子享受到更加个性化、科学化、高效化的教学资源和模式，做一个教育公平道路上的践行者。
                </div>
              </div>
              <div class="line2-3-left">
                <img :src="fontAssetsURL(`info/组 126${aa}.png`)" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line3">
      <div class="line3-title">企业文化</div>
      <div class="line3-content">
        <div class="line3-content-item">
          <div class="line3-content-item-child">
            <img :src="fontAssetsURL(`mobile/info/组 100.png`)" alt="" />
          </div>
          <div>使命</div>
          <div>用科技让教育更高效，更公平！打造教育界的AlphaGo！</div>
        </div>
        <div class="line3-content-item">
          <div class="line3-content-item-child">
            <img :src="fontAssetsURL(`mobile/info/理念.png`)" alt="" />
          </div>
          <div>理念</div>
          <div>
            为培训机构赋能 <br />
            为传统老师赋能 <br />
            为天下学子赋能
          </div>
        </div>
        <div class="line3-content-item">
          <div class="line3-content-item-child">
            <img :src="fontAssetsURL(`mobile/info/组 101 拷贝.png`)" alt="" />
          </div>
          <div>主张</div>
          <div>
            机构—降本提效，让办学变简单，教师—为老师减负，告别咽喉炎，学生—让孩子快速提高成绩，爱上学习。
          </div>
        </div>
        <div class="line3-content-item">
          <div class="line3-content-item-child">
            <img :src="fontAssetsURL(`mobile/info/组 105 拷贝.png`)" alt="" />
          </div>
          <div>价值观</div>
          <div>
            创新共赢 <br />
            同心追梦
          </div>
        </div>
      </div>
    </div>
    <div class="line4">
      <div class="line4-title">发展历程</div>
      <!-- 时间轴组件 -->
      <!-- <time-axis :showme="showme" :obj="objArr"></time-axis> -->
      <div class="line4-box">
        <div class="line4-left">
          <img
            v-if="this.historyPage !== 1"
            class="img"
            @click="changeHistoryPage(0)"
            :src="fontAssetsURL(`mobile/info/左_深色.png`)"
          />
          <img v-else class="img" :src="fontAssetsURL(`mobile/info/左_浅色.png`)" />
        </div>
        <div class="line4-content">
          <div v-for="(items, indexs) of this.objArr" :key="indexs" class="line4-content-content">
            <div v-if="items.id == historyPage" class="line4-content-title">{{ items.date }}</div>
            <div v-for="(item, index) of items.content" :key="index">
              <div v-if="items.id == historyPage" class="line4-list">
                <div class="time">{{ item.day }}日</div>
                <div class="details">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="line4-right">
          <img
            v-if="this.historyPage !== this.objArr.length"
            class="img"
            @click="changeHistoryPage(1)"
            :src="fontAssetsURL(`mobile/info/右_深色.png`)"
          />
          <img v-else class="img" :src="fontAssetsURL(`mobile/info/右_浅色.png`)" />
        </div>
      </div>
    </div>
    <div class="line5" @mouseover="isShow = true" @mouseleave="isShow = false">
      <div class="line5-title">企业荣誉</div>
      <div class="line5-1" ref="line5">
        <div
          class="line5-1-item"
          :class="{ active: currentItem5 === index }"
          v-for="(item, index) in line5List"
          :key="item.id"
          @mouseover="currentItem5 = index"
        >
          <div class="content">
            <img :src="item.src" alt="" />
            <p>
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="line6">
      <div class="line6-title">合作伙伴</div>
      <div class="line6-1">
        <div class="line6-1-item" v-for="item in line6List" :key="item.id">
          <img :src="item.src" alt="" />
        </div>
      </div>
    </div>
    <div class="line7">
      <div class="line7-title">联系我们</div>
      <div class="line7-1">
        <div class="line7-1-left">
          <img :src="fontAssetsURL(`info/contactUs.png`)" alt="" v-if="!this.showme" />
          <!-- <img :src="fontAssetsURL(`info/组 114@2x.png`)" alt="" v-if="this.showme" /> -->
        </div>
        <div class="line7-1-right">
          <div class="line7-1-right-1">
            <div class="img">
              <img :src="fontAssetsURL(`info/图层 1829.png`)" alt="" v-if="!this.showme" />
            </div>
            <!-- <img :src="fontAssetsURL(`info/图层 1829@2x.png`)" alt="" v-if="this.showme" /> -->
            公司地址：长沙市芙蓉区古汉国际广场1栋11楼
          </div>
          <div class="line7-1-right-1">
            <div class="img">
              <img :src="fontAssetsURL(`info/图层 1830.png`)" alt="" v-if="!this.showme" />
            </div>
            <!-- <img :src="fontAssetsURL(`info/图层 1830@2x.png`)" alt="" v-if="this.showme" /> -->
            官方公众号：晓果智学
          </div>
          <div class="line7-1-right-1">
            <div class="img">
              <img :src="fontAssetsURL(`info/图层 1831.png`)" alt="" v-if="!this.showme" />
            </div>
            <!-- <img :src="fontAssetsURL(`info/图层 1831@2x.png`)" alt="" v-if="this.showme" /> -->
            咨询电话：15616705276
          </div>
          <div class="line7-1-right-1">
            <div class="img">
              <img :src="fontAssetsURL(`info/图层 1832.png`)" alt="" v-if="!this.showme" />
            </div>
            <!-- <img :src="fontAssetsURL(`info/图层 1832@2x.png`)" alt="" v-if="this.showme" /> -->
            公司邮箱：guoshiedu@qq.com
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import timeAxis from "./infoComp/timeaxis.vue";

// params:cls [选择器 .class ]
// 获取元素信息
import { fontAssetsURL } from "@/utils.js";
function getBoxInfo(cls) {
  return getDom(cls).getBoundingClientRect();
}

function getDom(cls) {
  return document.querySelector(cls);
}

export default {
  data() {
    return {
      showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      aa: 0,
      line5List: [], //企业荣誉证书的集合
      currentItem5: 0, //当前证书项
      isShow: 1, //左右切换icon显示开关
      isShowone: false, //line5左切换icon显示亮度
      objArr: [], //传递给子组件消息
      line6List: [], //合作伙伴的集合
      currentItem6: 0, //当前合作伙伴项
      a: 0, //叠加值
      historyPage: 12, //发展历程默认页面
    };
  },
  // components: {
  //   timeAxis,
  // },
  created() {
    this.getData();
  },
  methods: {
    // 更改发展历程
    changeHistoryPage(id) {
      if (id == 1 && this.historyPage < this.objArr.length) {
        this.historyPage++;
      }
      if (id == 0 && this.historyPage > 1) {
        this.historyPage--;
      }
    },
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      let a = currentWidth > 1920 ? "" : "";
      this.aa = a;
      this.showme = currentWidth > 1920 ? 0 : 0;
      this.line5List = [
        {
          id: 0,
          src: fontAssetsURL(`info/组 126${a}(2).png`),
          content: "2019年度在线教育最具潜力品牌",
        },
        {
          id: 1,
          src: fontAssetsURL(`info/组 127${a}.png`),
          content: "全国工业和信息化人才培养工程培训基地",
        },
        {
          id: 2,
          src: fontAssetsURL(`info/组 128${a}.png`),
          content: "AAA级企业信用认证",
        },
        {
          id: 3,
          src: fontAssetsURL(`info/组 129${a}.png`),
          content: "中国青少年素质教育示范基地",
        },
        {
          id: 4,
          src: fontAssetsURL(`info/组 130${a}.png`),
          content: "教育科技创新力影响品牌",
        },
        {
          id: 5,
          src: fontAssetsURL(`info/组 131${a}.png`),
          content: "中国教育行业最具有影响力品牌",
        },
        {
          id: 6,
          src: fontAssetsURL(`prize/组 127${a}(1).png`),
          content: "中国百强改革创新示范企业",
        },
        {
          id: 7,
          src: fontAssetsURL(`prize/组 127${a}.png`),
          content: "晓果智学AI智能教育教研团队认证",
        },
        {
          id: 8,
          src: fontAssetsURL(`prize/组 127${a}(2).png`),
          content: "中国315消费者可信赖产品",
        },
        {
          id: 9,
          src: fontAssetsURL(`prize/组 127${a}(3).png`),
          content: "中国教育培训行业优秀服务商",
        },
      ];
      // line6图片集合
      (this.line6List = [
        {
          id: 0,
          src: fontAssetsURL(`info/组 113${a}.png`),
        },
        {
          id: 1,
          src: fontAssetsURL(`info/组 126${a}(3).png`),
        },
        {
          id: 2,
          src: fontAssetsURL(`info/组 127${a}(1).png`),
        },
        {
          id: 3,
          src: fontAssetsURL(`info/组 128${a}(1).png`),
        },
        {
          id: 4,
          src: fontAssetsURL(`info/组 129${a}(1).png`),
        },
        {
          id: 5,
          src: fontAssetsURL(`info/组 130${a}(1).png`),
        },
        {
          id: 6,
          src: fontAssetsURL(`info/组 131${a}(1).png`),
        },
        {
          id: 7,
          src: fontAssetsURL(`info/矩形 30 拷贝${a}.png`),
        },
        {
          id: 8,
          src: fontAssetsURL(`info/矩形 30 拷贝 3${a}.png`),
        },
        {
          id: 9,
          src: fontAssetsURL(`info/矩形 30 拷贝 4${a}.png`),
        },
        {
          id: 10,
          src: fontAssetsURL(`info/矩形 30 拷贝 5${a}.png`),
        },
        {
          id: 11,
          src: fontAssetsURL(`info/组 137${a}.png`),
        },
        {
          id: 12,
          src: fontAssetsURL(`info/矩形 30 拷贝 2${a}(1).png`),
        },
        {
          id: 13,
          src: fontAssetsURL(`info/矩形 30 拷贝 4${a}(1).png`),
        },
        {
          id: 14,
          src: fontAssetsURL(`info/矩形 30 拷贝 5${a}(1).png`),
        },
        {
          id: 15,
          src: fontAssetsURL(`info/博学${a}.png`),
        },
        {
          id: 16,
          src: fontAssetsURL(`info/和美${a}.png`),
        },
        {
          id: 17,
          src: fontAssetsURL(`info/小蜡笔${a}.png`),
        },
      ]),
        //传递给子组件
        (this.objArr = [
          {
            id: 1,
            date: "2020年1月",
            content: [
              {
                id: 0,
                day: 10,
                content: "晓果智学学生端、教师端、后台管理国家版权局计算机软件著作权",
              },
            ],
          },
          {
            id: 2,
            date: "2020年3月",
            content: [
              {
                id: 0,
                day: 27,
                content: "湖南果实智能科技有限公司荣获AAA级信用企业",
              },
            ],
          },
          {
            id: 3,
            date: "2020年4月",
            content: [
              {
                id: 0,
                day: 20,
                content: "晓果智学团队荣获国家级课题科研成果一等奖",
              },
              {
                id: 1,
                day: 25,
                content: "字强不息教育集团战略投资晓果智学",
              },
            ],
          },
          {
            id: 4,
            date: "2020年6月",
            content: [
              {
                id: 0,
                day: 5,
                content: "湖南果实智能科技有限公司正式获得ICP经营许可证",
              },
              {
                id: 1,
                day: 18,
                content: "晓果智学2.0产品发布会暨种子合伙人会议在杭召开",
              },
            ],
          },
          {
            id: 5,
            date: "2020年8月",
            content: [
              {
                id: 0,
                day: 30,
                content: "晓果智学广东首家直营校——东莞恒大校区正式开业",
              },
            ],
          },
          {
            id: 6,
            date: "2020年9月",
            content: [
              {
                id: 0,
                day: 25,
                content: "晓果智学加盟校突破100家",
              },
            ],
          },
          {
            id: 7,
            date: "2020年11月",
            content: [
              {
                id: 0,
                day: 19,
                content: " 晓果智学初中会考王正式上线",
              },
            ],
          },
          {
            id: 8,
            date: "2020年12月",
            content: [
              {
                id: 0,
                day: 7,
                content: "湖南果实智能科技有限公司入选“浙江省科技型中小企业”",
              },
              {
                id: 1,
                day: 19,
                content: "晓果智学亮相“粤港澳大湾区校外教育峰会”，并与深圳托管协会签订战略合作协议",
              },
              {
                id: 2,
                day: 21,
                content: " 晓果智学智能英语正式上线",
              },
              {
                id: 3,
                day: 24,
                content: "晓果智学加盟校突破200家，遍布150座城市",
              },
            ],
          },
          {
            id: 9,
            date: "2021年1月",
            content: [
              {
                id: 0,
                day: 8,
                content: "湖南果实智能科技有限公司正式在股权交易中心挂牌",
              },
              {
                id: 1,
                day: 12,
                content: "晓果智学加盟校突破400家！",
              },
              {
                id: 2,
                day: 22,
                content: "晓果智学荣登央视CCTV全频道",
              },
            ],
          },
          {
            id: 10,
            date: "2021年2月",
            content: [
              {
                id: 0,
                day: 20,
                content: "晓果智学再添三项国家计算机软件著作权登记证书",
              },
            ],
          },
          {
            id: 11,
            date: "2021年3月",
            content: [
              {
                id: 0,
                day: 13,
                content: "荣获《中国315消费者可信赖产品》",
              },
            ],
          },
          {
            id: 12,
            date: "2021年4月",
            content: [
              {
                id: 0,
                day: 25,
                content: "晓果智学全新3.0正式上线",
              },
              {
                id: 1,
                day: 26,
                content: "晓果智学与CCTV战略合作正式签约",
              },
              {
                id: 2,
                day: 26,
                content: "晓果智学与贝尔安亲签订战略合作协议",
              },
              {
                id: 3,
                day: 30,
                content: "晓果智学加盟校突破500家！",
              },
            ],
          },
        ]);
    },
    //后退
    goNext() {
      this.isShowone = true;
      if (Date.now() - this.preTime < 500) {
        return;
      }
      this.preTime = Date.now();

      const outBoxInfo = getBoxInfo(".line5"),
        inBoxInfo = getBoxInfo(".line5-1");

      if (outBoxInfo.right + 50 < inBoxInfo.right) {
        this.a -= 316;
        getDom(".line5-1").style.transform = `translate(${this.a}px)`;
      }
    },
    //前进
    goPre() {
      if (Date.now() - this.preTime < 500) {
        return;
      }
      this.preTime = Date.now();

      const outBoxInfo = getBoxInfo(".line5"),
        inBoxInfo = getBoxInfo(".line5-1");

      if (outBoxInfo.left > inBoxInfo.left) {
        this.a += 316;
        getDom(".line5-1").style.transform = `translate(${this.a}px)`;
      } else {
        this.isShowone = false;
      }
    },
    //轮播图边界值获取
    isEnd() {
      const maxleft = document.querySelector(".line5-1 > :nth-child(1)");
      const maxright = document.querySelector(".line5-1-item:last-child");
      const currentWidth = document.documentElement.clientWidth;
      let result = [
        maxleft.getBoundingClientRect().left,
        maxright.getBoundingClientRect().right - currentWidth,
      ];
      // console.log(result)
      return result;
    },
  },
};
</script>

<style scoped>
@import "../style_mobile/info.css";
</style>
